<template>
  <p class="autosave">
    <span v-if="data.status == 0" class="saving"></span>
    <span v-if="data.status == 1 || data.status == 3" class="saved"></span>
    <span v-if="data.status == 2" class="error"></span>
    <a v-if="data.status == 0">Sauvegarde...</a>
    <a v-if="data.status == 1 && data.lastSave == ''">À jour</a>
    <!-- For first displaying -->
    <a v-if="data.status == 1 && data.lastSave != ''"
      >Mis à jour à {{ appendLeadingZeroes(data.lastSave) }}</a
    >
    <a v-if="data.status == 2">Erreur !</a>
    <a v-if="data.status == 3">Insertion </a>
  </p>
</template>

<script>
//LIBS

export default {
  name: "autosaveinfo",
  props: {
    lastSave: {
      required: false,
    },
    status: {
      default: 0,
    },
    data: {
      default: {},
    },
  },
  // Status 0 = saving;
  // Status 1 = Saved;
  // Status 2 = Error;
  events: ["save", "newSaveData"],
  created() {
    window.addEventListener("beforeunload", (event) => {
      if (this.data.status != 1) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "";
      }else{
          event.returnValue
      }
    });
  },
  mounted() {
    if (this.data.status == undefined) {
      console.warn("[AUTO SAVE] Status undefined");
      this.data.status = 1;
    }
    if (this.data.lastSave == undefined) {
      this.data.lastSave = "";
    }
    if (this.data.lastKeyUp == undefined) {
      this.data.lastKeyUp = "";
    }

    //AutoSave Interval
    setInterval(() => {
      var currentTime = new Date();
      var delta = (currentTime - this.data.lastKeyUp) / 1000;
      const saveDelay = 2;
      if (saveDelay == Math.round(delta)) {
        //SAVE
        //console.debug("Saving data...")
        this.$emit("save");
        this.data.lastSave = (() => {
          return new Date();
        })();

        this.data.status = 1;
        this.$emit("newSaveData", this.data);
      }
    }, 1000);
  },
  methods: {
    appendLeadingZeroes(date) {
      //date = new Date(date)
      let h;
      let m;

      if (typeof date == "object") {
        h = Number(date.getHours());
        m = Number(date.getMinutes());
      } else {
        h = Number(date.match(/\d{1,2}/g)[0]);
        m = Number(date.match(/\d{1,2}/g)[1]);
      }

      if (h <= 9) {
        h = "0" + h;
      }

      if (m <= 9) {
        m = "0" + m;
      }

      return h + ":" + m;

      /*

            let dateToday = new Date()
            if(dateToday.toLocaleDateString('fr-FR') == date.toLocaleDateString('fr-FR') ){
                return "à " + h + ":" + m
            }else{
                return "le " + date.toLocaleDateString('fr-FR')  + " à " + h + ":" + m
            }
            */
    },
  },
};
</script>

<style scoped>
.autosave {
  position: absolute;
  font-size: 0.9em;
  font-weight: 300;
  /* top:-80px;*/
  right: 20%; /* before 0 */
  text-align: right;
}

.autosave > span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

span.saving {
  background-color: rgb(240, 146, 58);
}

span.saved {
  background-color: rgb(107, 201, 89);
}
span.error {
  background-color: var(--color_negatif);
}
</style>