<template>
  <div>
    <img v-if='image != null && image != undefined && image != ""' id="img" class="center" ref="imageElement" @click="clicking" :src="image" :key="image" :style="{'cursor : pointer' : editable}">
    <img v-else class="center placeholder" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" @click="clicking" :style="{'cursor : pointer' : editable}"/> 
    <input type="file" ref="file" hidden @change="new_image($event)">
  </div>
</template>

<script>
import file_api from '../../api/file'

//FILES
import logo_placeholder from '../../assets/logo_placeholder.png'

//REGEX
const url_regex =/(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/

export default {
  name: 'EditableImage',
  props: {
    'src_img' : null,
    'src_default' : null,
    "editable" : {
      type: Boolean,
      default : false
    },
    "image_type" : "" //If logo, display logo place holder
  },
  emits: ['change_image', "change"],
  
  data(){
    return{
      image: ""
    }
  },
  mounted(){
    this.load_image()
  
  },
  watch: {
    src_img(val){
      this.load_image()
    }
  },
  methods: {
    clicking(){
      if (this.editable) {
        this.$refs.file.click()
      }
    },
    load_image(){
      if ((this.src_img != undefined) || (this.src_img != null)){
        if (url_regex.test(this.src_img)){
          //IF INPUT SRC IS AN URL (Blob)
          this.image = this.src_img
        } else {
          //IF INPUT SRC IS AN _id => Fetch it !
          file_api.get(this.src_img)
            .then((image)=>{
              this.image = image
            })
            .catch((err)=>console.error("[IMAGE COMPO]", err))
        }
      } else if (this.src_default != undefined) {
        if (this.image_type == "logo"){
          this.image = logo_placeholder
        } else {
          file_api.get(this.src_default)
          .then((image)=>{
            this.image = image
          })
          .catch((err)=>console.error("[IMAGE COMPO]", err))
        }
      }
    },
    new_image(event){
      let file = event.target.files[0]
      this.image= URL.createObjectURL(file)
      this.$emit('change_image', file)
      this.$emit('change', {target : { url : this.image, file : file},  } ) //Emulate classique input event
    }
  }
}
</script>
    
<style scoped>

  #img {
    position: relative;
    border-radius: var(--border_radius);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    cursor: pointer;
  }

  .placeholder{
    height: 100%;
    width : 100%
  }
</style>
