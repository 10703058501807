

function displayDate(date) {
  if (date != undefined) {
    date = new Date(date);
    let month = String(date.getMonth()).length > 1 ? date.getMonth() + 1  : "0" + (date.getMonth() + 1);
    let day = String(date.getDate()).length > 1 ? date.getDate() : "0" + date.getDate();
    return day + "/" + month + "/" + date.getFullYear();
  } else {
    return "-";
  }
}


export { displayDate }