<template >
  <section id="template"  @drop="drop($event)"
      @dragover="dragover" >
     
    <div 
      class="inline"
      style="margin-top: auto; margin-bottom: auto"
    

    >
       <label for="">Template:</label>
      <p v-if="src_file.media_data == undefined && file == null"       @click="$refs.file.click()">
        Déposez votre fichier
      </p>
      <p v-if="file != null && displayed_name != ''" @click="$refs.file.click()">{{ displayed_name }} </p>

      <input
        type="file"
        @change="onChange('file')"
        hidden
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
        max="1"
      />

      <img
        :class="
          file == null && src_file.media_data == undefined ? 'greyEffect' : ''
        "
        src="../../assets/download.svg"
        alt=""
        style="width: 25px; height: 25px; margin-top: auto; margin-bottom: auto"
        @click="dl_file"
      />
    </div>

   
    <div >
      <input
        type="text"
        v-model="rename"
        placeholder="changer le nom du template"
      />
      <img
        :class="
          file == null && src_file.media_data == undefined || rename == '' ? 'greyEffect' : ''
        "
        src="../../assets/send_arrow.png"
        alt=""
        style="width: 25px; height: 25px; margin-top: auto; margin-bottom: auto"
        @click="input_name"
      />
    </div>
    <!--  <div class="inline">
      <div v-if="id == undefined && file==undefined">Pas de template</div>
      <iptString  @input='input_name'></iptString>
    <div v-if="id || file">template.aepx</div> 
      <button @click="dl_file" :disabled="id == undefined && file == undefined">download</button>
      <button @click="$refs.file.click()">upload</button>
      <input type="file" ref="file" hidden @change="upload_file($event)" />
    </div>
    <div>
      <p style="color: red" v-if="error">{{ error }}</p>
    </div>-->
  </section>
</template>

<script>
//NPM
import { base64StringToBlob } from 'blob-util';
//COMPONENTS
import editableText from "./editableText.vue";
export default {
  name: "iptTemplate",
  props: ["id", "src_file"],
  emits: ["change_template", "change_template_name"],
  components: { editableText },
  data() {
    return {
      rename: "",
      displayed_name: "",
      image: "",
      src_input: "",
      error: "",
      file: null,
    };
  },
  mounted() {
    if (this.src_file.media_data != undefined) {
      this.file = this.src_file;
      if (this.src_file.media_filename != "") {
        this.displayed_name = this.src_file.media_filename;
      } else {
        this.displayed_name = "sans_nom.aepx";
      }
    }
  },
  watch: {
    src_file: function (src_file) {
      if (src_file == undefined || src_file.media_data == undefined) {
        this.file = null;
        this.displayed_name= ""
      } else {
        this.file = src_file;
        if (src_file.media_filename != "" && src_file.media_filename  != undefined) {
          this.displayed_name =src_file.media_filename;
        } else {
          this.displayed_name = "sans_nom.aepx";
        }
      }
    },
  },
  computed: {},
  methods: {
    drop(event) {
      //input is ref_name of input where drop function is called, ref_name need to be equal to field name where file in saved in data
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;

      this.onChange(); // Trigger the onChange event manually
    },
    dragover(event) {
      event.preventDefault();
    },
    onChange() {
      //input is ref_name of input where drop function is called, ref_name need to be equal to field name where file in saved in data
      this.file = this.$refs.file.files[0];
      this.$nextTick(() => {
        this.displayed_name = this.file.name;
         this.$emit("change_template", this.file);
      });
      //
    },
    input_name() {
      if (this.file != null && this.rename != "") {
        this.displayed_name =
          this.rename.split(".")[0] + "." + this.displayed_name.split(".")[1];
        this.$nextTick(() => {
          this.rename = "";
          this.$emit("change_template_name", this.displayed_name);
        });
      }
    },
    dl_file() {
      if(this.file != null){
        let download = null;
        if(this.file.media_data == undefined){
           download = URL.createObjectURL(this.file)
        
         //
        }else{
          download = URL.createObjectURL(base64StringToBlob(this.file.media_data, this.file.media_mimetype))
        }
        
        this.$nextTick(()=>{
           var fileLink = document.createElement("a");

          fileLink.href =download;
          fileLink.setAttribute("download", this.displayed_name);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        
      }
     /* file_api
        .get(this.id)
        .then((res) => {
          var fileURL = res;
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.displayed_name);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          this.error = "Pas encore de fichier template";
          setTimeout(() => {
            this.error = "";
          }, 3000);
        });*/
    },
    upload_file(event) {
      this.file = event.target.files[0];
      this.$nextTick(()=>{
       
      })
 
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="@/views/Admin/subViews/style.css" scoped></style>
<style scoped>
#template {
  
  white-space: nowrap;
}
.greyEffect {
  filter: invert(0.7);
}
.inline{
  width: inherit;
}
</style>
 