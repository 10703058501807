<template>
  <div :class="{ vdo_name: type != 'date' }" :id="id">
    <autosaveinfo
      v-if="type == 'text'"
      :data="autoSave"
      @save="update()"
      style="display: none; width: 0%"
    />
    <input
      v-if="balise == 'text'"
      type="text"
      ref="text"
      v-model="name"
      @keyup="autoSave.lastKeyUp = new Date()"
      @blur="
        autoSave.lastKeyUp = autoSave.lastSave;
        update();
      "
      placeholder="Indiquer le titre de votre vidéo"
      autocomplete="off" data-lpignore="true" data-form-type="other"
    />

    <input
      v-if="balise == 'date'"
      type="date"
      ref="date"
      v-model="date"
      @change="update"
      @blur="balise = 'a'"
    />

    <a v-if="balise == 'a'" @click="edit">
      {{ displayedValue }}
    </a>

    <a
      v-if="
        (balise == 'a' && name == '') || (name == undefined && type == 'text')
      "
      @click="edit"
      style="color: #a9a9a9"
    >
      Indiquer le titre de votre vidéo
    </a>
  </div>
</template>

<script>
//COMPONENTS
import autosaveinfo from "./AutoSaveInfo";

//LIB
import { displayDate } from "../../../src/lib/date";
export default {
  name: "Vdoname",
  components: { autosaveinfo },
  data: function () {
    return {
      balise: "a",
      name: String,
      date: Date,
      autoSave: {
        status: 1,
        lastKeyUp: Date,
        lastSave: "",
      },
    };
  },
  emits: ["finished"],
  props: {
    type: {
      required: false,
      default: "text",
    },
    value: {
      required: true,
    },
    editable : {
      required: false,
      default: false
    },
    id : {
       required: false,
    }
  },
  watch: {
    value: function(value) {
      if (this.type == "text") {
        if (this.name != value) {
          this.name = value;
        }
      } else {
        this.date = value;
      }
    },
  },
  mounted() {


    if (this.type == "text") {
      if (this.name != this.value) {
        this.name = this.value;
      }
    } else {
      this.date = this.value;
    }
  },
  computed: {
    displayedValue() {
      if (this.type == "text") {
        return this.name;
      } else {
        return this.displayDate(this.date);
      }
    },
  },
  methods: {
    displayDate,
    edit: function () {
      if (this.editable) {
        this.balise = this.type;
        this.$nextTick(() => {
          // Wait transformation in input
          // Click to edit after transform in input
          this.$refs[this.type].focus();
        });
      }
    },
    update: function () {
      if (this.type == "text") {
        if (this.name != this.value) {
          this.$emit("finished", this.name );
        }
      } else {
        this.$emit("finished", this.date);
      }
      this.balise = "a";
    },
  },
};
</script>


<style scoped>
.vdo_name {
  width: 100%;
  mask-image: -webkit-gradient(
    linear,
    100% top,
    95% top,
    from(transparent),
    to(rgba(0, 0, 0, 1))
  );
  -webkit-mask-image: -webkit-gradient(
    linear,
    100% top,
    95% top,
    from(transparent),
    to(rgba(0, 0, 0, 1))
  );
}

input {
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0;
  font-weight: 400;
  width: 100%;
  border-radius: 0px;
}

.vdo_name > a {
  cursor: text;
}
</style>